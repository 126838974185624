import { ErrorPage } from '@/components/layouts/ErrorPage/ErrorPage';

const NotFoundPage = () => {
  return (
    <ErrorPage
      title="ページが見つかりませんでした"
      description={
        <>
          申し訳ございません。
          <br />
          指定されたページは移動、削除されたか
          <br />
          一時的に利用できない可能性がございます。
        </>
      }
    />
  );
};

export default NotFoundPage;
