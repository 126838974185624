import {
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { HiMenu } from 'react-icons/hi';
import useAuth from '@/hooks/useAuth';
import { Link } from '@/components/elements/Link/Link';

export type HeaderProps = {
  title?: string;
};

export const Header: FC<HeaderProps> = ({ title, ...props }) => {
  const left = title ? <HeaderLogo /> : <Spacer />;
  const center = title ? (
    <Text textAlign='center' color='white'>
      {title}
    </Text>
  ) : (
    <HeaderLogoHorizontal />
  );

  return (
    <HStack
      h='50px'
      position='sticky'
      top={0}
      left={0}
      right={0}
      bg='base'
      paddingX={4}
      justifyContent='space-between'
      alignItems='center'
      zIndex={2}
    >
      <Flex flexBasis='50px' justifyContent='flex-start'>
        {left}
      </Flex>
      <Flex flex={1} justifyContent='center'>
        {center}
      </Flex>
      <Flex flexBasis='50px' justifyContent='flex-end'>
        <HeaderMenu />
      </Flex>
    </HStack>
  );
};

const HeaderLogo = () => {
  return (
    <Link href='/' lineHeight={1}>
      <Image
        src='/images/logoWhite.png'
        width={32}
        height={32}
        alt='トレーニングシェア'
      />
    </Link>
  );
};

const HeaderLogoHorizontal = () => {
  return (
    <Link href='/' lineHeight={1}>
      <Image
        src='/images/logoHorizontal.png'
        width={142}
        height={32}
        alt='トレーニングシェア'
      />
    </Link>
  );
};

const HeaderMenu = () => {
  const { isAuthenticated, isActionRequired } = useAuth();
  const isGuest = !isAuthenticated && !isActionRequired;
  const router = useRouter();
  return (
    <Menu>
      <MenuButton aria-label='メニュー'>
        <HiMenu color='white' size={28} />
      </MenuButton>
      <MenuList>
        {isGuest && (
          <>
            <MenuItem onClick={() => router.push('/')}>トップページ</MenuItem>

            <MenuItem onClick={() => router.push('/signup')}>
              メールアドレスで登録
            </MenuItem>

            <MenuItem onClick={() => router.push('/signin')}>ログイン</MenuItem>
          </>
        )}

        {isAuthenticated && (
          <>
            <MenuItem onClick={() => router.push('/top?howToUse=open')}>
              トレーニングシェアとは
            </MenuItem>

            <MenuItem onClick={() => router.push('/news')}>
              新着情報一覧
            </MenuItem>

            <MenuItem onClick={() => router.push('/glossary')}>用語集</MenuItem>

            <MenuItem onClick={() => router.push('/profile')}>
              プロフィール編集
            </MenuItem>

            <MenuItem onClick={() => router.push('/credit_card')}>
              クレジットカード変更
            </MenuItem>
          </>
        )}

        <MenuItem onClick={() => router.push('/contact')}>
          お問い合わせ
        </MenuItem>

        {!isGuest && (
          <MenuItem onClick={() => router.push('/signout')}>
            ログアウト
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

Header.displayName = 'Header';
