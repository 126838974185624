import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import Head from 'next/head';
import { FC, ReactNode } from 'react';
import { ButtonLink } from '@/components/elements/ButtonLink/ButtonLink';
import { Title } from '@/components/metas/Title/Title';
import { Container } from '../Container/Container';
import { Header } from '../Header/Header';
import { Wrapper } from '../Wrapper/Wrapper';

type ErrorPageProps = {
  title: string;
  description: ReactNode;
  link?: ReactNode;
};

export const ErrorPage: FC<ErrorPageProps> = ({ title, description, link }) => {
  return (
    <Wrapper header={<Header />}>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <Title title={title} />
      <Container>
        <Box position="fixed" top={50} left={0} right={0} bottom={0}>
          <VStack
            h="100%"
            justifyContent="center"
            alignItems="stretch"
            spacing={8}
          >
            <VStack justifyContent="center" spacing={4}>
              <Heading fontSize="lg" textAlign="center">
                {title}
              </Heading>
              <Text fontSize="sm" textAlign="center">
                {description}
              </Text>
            </VStack>

            <VStack flexBasis="80px">
              <Container textAlign="center">
                {link || <ButtonLink href="/">トップページへ</ButtonLink>}
              </Container>
            </VStack>
          </VStack>
        </Box>
      </Container>
    </Wrapper>
  );
};

ErrorPage.displayName = 'ErrorPage';
